<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Spinner,
        Tabs,
        TabItem,
        Textarea,
        Modal,
        Dropdown,
        DropdownItem,
        Checkbox,
    } from "flowbite-svelte";
    import { onMount } from "svelte";
    import {
        async_ask_llm,
        deleteAllFiles,
        download_file,
        fetchFiles,
    } from "../service/http_calls";
    import {
        defend,
        defend_instructions_modal_open,
        defend_submit_loading,
        files_list,
        initial_defend,
        selected_section,
        selected_sub_section,
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import SingleDefendItem from "./SingleDefendItem.svelte";
    import { toast } from "@zerodevx/svelte-toast";
    import { ChevronDownOutline } from "flowbite-svelte-icons";

    let clearResponsesModal = false;
    let downloading_file = false;
    let activeTab = "standard";

    onMount(async () => {
        const resp = await fetchFiles("defend");

        files_list.update((prev) => {
            if (Array.isArray(resp)) {
                prev["defend"] = resp;
            }
            return prev;
        });
    });

    async function handleSubmit() {
        console.log("submit");
        defend_submit_loading.set(true);
        if (activeTab === "standard") {
            defend_submit_loading.set(true);
            let sections = [
                "already_established",
                "deducible",
                "how",
                "overcome",
                "limitations",
                "timeline",
                "documents",
                "gap",
                "resolution",
                "advance",
                "baseline",
                "patents",
                "uncertainty",
                "individuals",
                "new",
                "practice",
                "research",
                "methodology",
            ];

            for (let section of sections) {
                console.log("section", section);
                let resp = await async_ask_llm(
                    $defend.user_instructions,
                    "defend",
                    section,
                    "outline",
                    "gpt-4o",
                    [],
                );
                defend.update((prev) => {
                    prev[section]["outline"]["result_id"] = resp.result_id;
                    return prev;
                });
            }
        } else if (activeTab === "freeform") {
            defend_submit_loading.set(true);
            let resp = await async_ask_llm(
                $defend.user_instructions +
                    " HMRC question: " +
                    $defend.freeform.question,
                "defend",
                "freeform",
                "outline",
                "gpt-4o",
                [],
            );
            defend.update((prev) => {
                prev["freeform"]["outline"]["result_id"] = resp.result_id;
                return prev;
            });
        }
    }

    async function handleDownload(type) {
        downloading_file = true;

        if (activeTab === "standard") {
            let payload = {};
            Object.keys($defend).forEach((key) => {
                if (key !== "user_instructions") {
                    Object.keys($defend[key]).forEach((sub_key) => {
                        payload[key + "_" + sub_key] =
                            $defend[key][sub_key].generated_content;
                    });
                }
            });
            let resp = await download_file(payload, type, "defend", "Defend");
        } else {
            let payload = {
                "freeform_outline": $defend.freeform.outline.generated_content,
                "freeform_draft": $defend.freeform.draft.generated_content,
            };
            let resp = await download_file(payload, type, "defend_freeform", "Defend");
        }

        downloading_file = false;
    }

    $: submit_disabled =
        $files_list["defend"].length == 0 ||
        $files_list["defend"].some((file) => file.status !== "Ready") ||
        (activeTab === "freeform" && $defend.freeform.question.trim() === "");


    let clear_instructions = false;
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl" class="w-full mb-40">

            <ul
                class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
            >
                {#each ["standard", "freeform"] as tab}
                    <li class="me-2">
                        <a
                            href="#"
                            class="inline-block p-4 rounded-t-lg"
                            class:active={activeTab === tab}
                            class:text-blue-600={activeTab === tab}
                            class:bg-gray-100={activeTab === tab}
                            class:dark:bg-gray-800={activeTab === tab}
                            class:dark:text-blue-500={activeTab === tab}
                            on:click={() => (activeTab = tab)}
                        >
                            {tab === "standard"
                                ? "Standard questions"
                                : "Freeform questions"}
                        </a>
                    </li>
                {/each}
            </ul>

            <FilesTable module="defend"></FilesTable>

            {#if activeTab === "freeform"}
                <P class="my-5 text-gray-700 dark:text-gray-300 font-semibold"
                    >Note: Please add HMRC question here</P
                >
                <Textarea
                    rows="5"
                    class="mb-3"
                    bind:value={$defend.freeform.question}
                ></Textarea>
            {/if}

            <P class="my-5 text-gray-700 dark:text-gray-300 font-semibold"
                >Add instructions (Optional):</P
            >

            <Textarea
                rows="5"
                class="mb-3"
                bind:value={$defend.user_instructions}
            ></Textarea>
            <div class="flex justify-end my-5">
                {#if $defend_submit_loading}
                    <div class="flex justify-center my-5">
                        <Spinner></Spinner>
                    </div>
                {:else}
                    <Button
                        size="sm"
                        color="purple"
                        class="hover:bg-purple-700 transition-colors duration-200"
                        on:click={handleSubmit}
                        disabled={submit_disabled}>Submit</Button
                    >
                {/if}
            </div>

            {#if activeTab === "standard"}
                <P size="lg" class="my-5 font-bold"
                    ><Span gradient>Advance</Span></P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >An explanation of what the scientific or technological
                    advance(s) is.</P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Your response must include:</P
                >

                <ul
                    class="list-disc list-inside mb-5 space-y-2 text-gray-700 dark:text-gray-300"
                >
                    <li>
                        What the gap in technological knowledge or capability
                        was which necessitated the commencement of the R&D.
                    </li>
                    <li>
                        The baseline in the science or technology that the
                        advance was being measured against.
                    </li>
                    <li>
                        What scientific or technological advance is being sought
                        or achieved during the development of the project and
                        not just your company's knowledge or capability alone.
                    </li>
                    <li>
                        How this R&D project is new, or an appreciable
                        improvement to the field of technology relative to what
                        is available in the public domain and not readily
                        deducible by a competent professional.
                    </li>
                    <li>
                        If any patents were filed against the advance in the
                        field of science or technology. If none were filed,
                        please explain why you decided not to protect your
                        intellectual property.
                    </li>
                </ul>

                <P class="font-semibold mt-20 mb-5"
                    >What the gap in technological knowledge or capability was
                    which necessitated the commencement of the R&D</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="gap"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="gap"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold  mt-20 mb-5"
                    >The baseline in the science or technology that the advance
                    was being measured against</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="baseline"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="baseline"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold mt-20 mb-5"
                    >What scientific or technological advance is being sought or
                    achieved during the development of the project and not just
                    your company's knowledge or capability alone</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="advance"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="advance"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold  mt-20 mb-5"
                    >How this R&D project is new, or an appreciable improvement
                    to the field of technology relative to what is available in
                    the public domain and not readily deducible by a competent
                    professional</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="new"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="new"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold   mt-20 mb-5"
                    >If any patents were filed against the advance in the field
                    of science or technology. If none were filed, please explain
                    why you decided not to protect your intellectual property</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="patents"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="patents"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P size="lg" class="mt-20 mb-5 font-bold"
                    ><Span gradient>Uncertainty</Span></P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >An explanation of what the scientific or technological
                    uncertainties involved in the project were.</P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Please provide an explanation of the research undertaken to
                    establish these were uncertainties in the overall field of
                    technology and can't be solved by readily deducible methods
                    within your company's knowledge or by information available
                    in the public domain. Bear in mind that just because your
                    company does not know if something could be done, does not
                    make it an uncertainty.</P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Your response must include an explanation, in a form
                    understandable to the non-expert, of what these
                    uncertainties were, stating:</P
                >

                <ul
                    class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300"
                >
                    <li>The current technological limitations</li>
                    <li>
                        Current practice and why it fails to resolve the
                        uncertainty
                    </li>
                    <li>
                        An explanation of the research undertaken to establish
                        these were uncertainties in the overall field of
                        technology that could not be solved by the competent
                        professional
                    </li>
                    <li>
                        Whether the methods used to solve the uncertainties were
                        not already established in the public domain
                    </li>
                </ul>

                <P class="font-semibold mt-20 mb-5"
                    >An explanation of what the scientific or technological
                    uncertainties involved in the project were</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="uncertainty"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="uncertainty"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold mt-20 mb-5"
                    >The current technological limitations</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="limitations"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="limitations"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold   mt-20 mb-5"
                    >Current practice and why it fails to resolve the
                    uncertainty</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="practice"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="practice"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold   mt-20 mb-5"
                    >An explanation of the research undertaken to establish
                    these were uncertainties in the overall field of technology
                    that could not be solved by the competent professional</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="research"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="research"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>
                <div class="my-30"></div>

                <P class="font-semibold   mt-20 mb-5"
                    >Whether the methods used to solve the uncertainties were
                    not already established in the public domain</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="already_established"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="already_established"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P size="lg" class="mt-20 mb-5 font-bold"
                    ><Span gradient>Resolution</Span></P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Detail of how and when the uncertainties were overcome.</P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Please provide us with the specific methodology undertaken
                    to overcome the uncertainties outlined in your response and
                    how these methods were not readily deducible by a competent
                    professional.</P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Please also provide a detailed timeline for your R&D
                    project. This must include all stages from planning to
                    deployment of the R&D outcome, what individuals were
                    involved at each stage, and any related documents you hold
                    that were produced throughout each stage.</P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >If the R&D is still underway, please just cover the
                    timeline up until the current position of the project and
                    give an explanation as to why the project is still in
                    progress. In the timeline, you should include details of:</P
                >

                <ul
                    class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300"
                >
                    <li>How and when the uncertainties were identified</li>
                    <li>How and when the uncertainties were overcome</li>
                    <li>All stages from planning to deployment</li>
                    <li>What individuals were involved at each stage</li>
                    <li>
                        Any related contemporaneous project documents that
                        informed and support the R&D relief claim
                    </li>
                </ul>

                <P class="font-semibold mt-20 mb-5"
                    >How and when the uncertainties were identified</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="how"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="how"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold   mt-20 mb-5"
                    >How and when the uncertainties were overcome</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="overcome"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="overcome"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold mt-20 mb-5"
                    >All stages from planning to deployment</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="timeline"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="timeline"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="mt-20 mb-5 text-gray-700 dark:text-gray-300 font-semibold"
                    >Please provide us with the specific methodology undertaken
                    to overcome the uncertainties outlined in your response and
                    how these methods were not readily deducible by a competent
                    professional</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="methodology"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="methodology"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold   mt-20 mb-5"
                    >What individuals were involved at each stage</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="individuals"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="individuals"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P class="font-semibold   mt-20 mb-5"
                    >Any related contemporaneous project documents that informed
                    and support the R&D relief claim</P
                >

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="documents"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="documents"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>

                <P size="lg" class="  mt-20 mb-5 font-bold"
                    ><Span gradient>Not Readily Deducible</Span></P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Detail as to why the knowledge being sought was not readily
                    deducible by a competent professional.</P
                >

                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >You'll need to show that the persons leading the R&D
                    project are themselves competent professionals working in
                    the relevant field. This might be done by outlining their
                    relevant background, professional qualifications, and recent
                    experience. Then have them explain why they consider the
                    uncertainties as scientific or technological uncertainties
                    rather than routine uncertainties.</P
                >

                <P class="font-semibold   mt-20 mb-5">Not readily deducible</P>

                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="deducible"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="deducible"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>
            {/if}
            {#if activeTab === "freeform"}
                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    <SingleDefendItem
                        title="Outline"
                        section="freeform"
                        sub_section="outline"
                    ></SingleDefendItem>
                    <SingleDefendItem
                        title="Draft"
                        section="freeform"
                        sub_section="draft"
                    ></SingleDefendItem>
                </Accordion>
            {/if}

            <div class="flex justify-end mt-10">
                <Button
                    color="red"
                    class="mr-5"
                    on:click={() => {
                        clearResponsesModal = true;
                    }}>Clear All</Button
                >
                {#if downloading_file}
                    <div class="flex justify-center">
                        <Spinner></Spinner>
                    </div>
                {:else}
                    <Button color="purple"
                        >Download<ChevronDownOutline
                            class="w-6 h-6 ms-2 text-white dark:text-white"
                        /></Button
                    >
                    <Dropdown>
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("docx");
                            }}>Docx</DropdownItem
                        >
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("pdf");
                            }}>PDF</DropdownItem
                        >
                        <DropdownItem
                            on:click={() => {
                                let text = "";
                                Object.keys($defend).forEach((key) => {
                                    if (key !== "user_instructions") {
                                        Object.keys($defend[key]).forEach(
                                            (sub_key) => {
                                                text +=
                                                    $defend[key][sub_key]
                                                        .generated_content +
                                                    "\n\n";
                                            },
                                        );
                                    }
                                });
                                toast.push("Copied to clipboard");
                                navigator.clipboard.writeText(text);
                            }}>Clipboard</DropdownItem
                        >
                    </Dropdown>
                {/if}
            </div>
            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >AI may make mistakes. Please review the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>

<Modal
    title="Clear All"
    bind:open={clearResponsesModal}
    autoclose
>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        Are you sure you want to clear all files and outputs?
    </p>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        This action cannot be undone.
    </p>
    <svelte:fragment slot="footer">
        <Checkbox bind:checked={clear_instructions}>Clear All Instructions</Checkbox>
        <Button
            color="red"
            on:click={async () => {
                console.log("clearing responses");
                defend.update((prev) => initial_defend);
                await deleteAllFiles("defend");
                files_list.update((prev) => {
                    prev["defend"] = [];
                    return prev;
                });
                toast.push("Responses and files cleared");
            }}>Clear</Button
        >
        <Button
            color="alternative"
            on:click={() => (clearResponsesModal = false)}>Cancel</Button
        >
    </svelte:fragment>
</Modal>

<Modal
    title="Add Instructions"
    bind:open={$defend_instructions_modal_open}
    autoclose
>
    <Textarea
        rows="5"
        bind:value={$defend[$selected_section][$selected_sub_section]
            .user_instructions}
    ></Textarea>
    <div class="flex justify-end">
        <Button
            on:click={() => {
                defend_instructions_modal_open.set(false);
            }}>Save</Button
        >
    </div>
</Modal>
